import React from 'react'
import { graphql } from 'gatsby'

import { Player, Layout, Fade } from '../components'
import {pickQuality} from "../../utils/pick-quality";

class CinemaDetail extends React.Component {

  constructor(props) {
    super(props)
    this.video = React.createRef()
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.video = this.video.current
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <Layout page={this}>
        <div className="movie">
          <div className="movie__bg" style={{backgroundImage: `url(${this.props.data.page.backgroundCover.url}?auto=format&fit=min&w=1200)`}}>
          </div>
          <div className="movie__head">
            <Fade to={`/cinema/`} className="font-head uppercase text-16 tracking-wide">All films</Fade>
          </div>
          <div className="wrapper">
            <div className="movie__content mx-auto xl:w-6/10">

              <div className="text-center pb-20">
                <h1 className="font-head tracking-wide leading-tight text-24 md:text-28">{this.props.data.page.title}</h1>
                <span className="font-main text-12 md:text-13 uppercase leading-tight">{this.props.data.page.subtitle}</span>
              </div>

              <div className="movie__player">
                <Player className="absolute pin-t w-full h-full" cover={`${this.props.data.page.cover.url}?auto=format&fit=min&w=900`} src={pickQuality(this.props.data.page)} ref={this.video} hasExpand></Player>
              </div>
              <div className="movie__info relative">
                <div className="movie__text md:w-6/8 md:ml-auto md:mb-40">
                  <h2>Synopsis</h2>
                  <div dangerouslySetInnerHTML={{ __html: this.props.data.page.synopsys }} ></div>

                  <div className="movie__sidebar md:absolute md:pin-t md:pin-l">
                    <div className="flex md:block">
                      <div className="w-1/2 md:w-full">
                        <h2>Year</h2>
                        <p>{this.props.data.page.year}</p>
                      </div>
                      <div className="w-1/2 md:w-full">
                        <h2>Length</h2>
                        <p>{this.props.data.page.duration}</p>
                      </div>
                    </div>
                  </div>

                  {
                    (() => {
                      if (this.props.data.page.extra) {
                       return (
                          <div dangerouslySetInnerHTML={{ __html: this.props.data.page.extra }} ></div>
                       )
                      }
                    })()
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default CinemaDetail

export const query = graphql`
  query MovieQuery($slug: String!) {
    page: datoCmsMovie(slug: { eq: $slug }) {
      title
      subtitle
      backgroundCover {
        url
      }
      cover {
        url
      }
      mp4Url
      mp4UrlSd
      year
      duration
      synopsys
      extra
    }
  }
`
